import React, { useState, useRef } from 'react';
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby';
import Container from '../components/Container';
import Sucursal from '../components/Sucursal';
import animateScrollTo from 'animated-scroll-to';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import Map from '../components/map';

export default (props)=>{
  const sucursales = props.data.allSucursalesJson.nodes;
  const scrollToRef = (ref) => animateScrollTo(ref.current);

  const [mainSucursal, setMainSucursal] = useState(sucursales[0]);

  const mapRef = useRef(null);

  const switchSucursal = (sucursal) => {
    setMainSucursal(sucursal);
    scrollToRef(mapRef);
  }

  return (
    <Layout>

      <PageHeader>
        <h2 className="text-6xl">Sucursales</h2>
        <p className="mt-2 text-sm">
          <Link to="/" className="underline">Inicio</Link>
          <i className="fas fa-chevron-right mx-2"></i>
          <Link to="/sucursales" className="underline">Sucursales</Link>
          <i className="fas fa-chevron-right mx-2"></i>
          <Link to="/tuxtla-gutierrez" className="underline">Tuxtla Gutiérrez</Link>
        </p>
      </PageHeader>

      <Container hideAside={true} >
        <div className="flex flex-col sm:flex-row mb-20 sm:mb-0 pt-4" ref={mapRef}>
          <div className="sm:flex-1 " >
            <Map lat={mainSucursal.coords.latitude} lng={mainSucursal.coords.longitude} ></Map>
          </div>
          <div className="sm:ml-6 mt-4 sm:mt-0" style={{ width: "300px" }}>
            <p className="rounded p-2 inline-block text-sm bg-blue-200 text-blue-800 mb-4">Estás viendo:</p>
            <Sucursal sucursal={mainSucursal} main={true} />
          </div>
        </div>
        <div className="flex flex-wrap">
          {
            sucursales.map((sucursal, index) => (
              <Sucursal sucursal={sucursal} key={index} view={() => switchSucursal(sucursal)} />
            ))
          }
        </div>
      </Container>
      <Footer />
    </Layout>
  );
}

export const query = graphql`
  query {
    allSucursalesJson(filter: {city: {eq: "Tuxtla Gtz"}},sort:{fields:[order,name], order:[DESC,ASC]}) {
      nodes {
        name
        address
        email
        phone
        coords{
          latitude
          longitude
        }
        horario{
          weekdays{
            starts_at
            ends_at
          }
          saturday{
            starts_at
            ends_at
          }
        }
      }
    }
    sucursalesJson(name:{eq:"Matriz"}){
    coords{
        latitude
        longitude
      }
    }
  }

`;